.wrapper {
  background: linear-gradient(90deg, rgba(36,45,66,1) 10%, rgba(60,75,100,1) 50%, rgba(36,45,66,1) 90%);
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding .15s);
}

.footer {
  background: rgb(46,58,81);
}