// Here you can add other styles
.bg-login {
  background: linear-gradient(90deg, rgba(36,45,66,1) 10%, rgba(60,75,100,1) 50%, rgba(36,45,66,1) 90%);
}

.text-right {
  text-align: right
}

.text-left {
  text-align: left
}

.collapsible-table {
  .row {
    padding: 0.5rem 0.5rem;    
  }
  .col {
    border-bottom: 1px solid lightgray;
  }
  [class*="col-"] {
    border-bottom: 1px solid lightgray;
  }
  .header {
    font-weight: bold;
  }
}

//Links
a {
  text-decoration: none !important;
}